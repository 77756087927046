<template>
	<div id="grupos">
		<div class="col-12 novo-grupo">
			<div class="row justify-end">
				<v-btn color="secondary" raised @click="dialog_grupo = true">
					<i class="mr-2 fas fa-plus"></i> Cadastrar grupo
				</v-btn>
			</div>
		</div>
        <CustomTable 
			v-if="headers != ''"
			:action="'getGrupos'"
			:getter="$store.getters.grupos"
			:headers="headers"
			:search="true"
			:title="'Grupos'"
			:pagination="true"
			:filters="filtros"
			ref="tabela"
		>
			<template v-slot:acoes="{ item }">
				<v-btn color="primary" raised x-small @click="editarGrupo(item._id)">
					Editar
				</v-btn>
			</template>
		</CustomTable>
		<v-dialog v-model="dialog_grupo" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span v-if="grupo.grupo_id" class="headline">Editar grupo</span>
					<span v-else class="headline">Nova grupo</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="form_grupo">
							<div class="row">
								<div class="col-12">
									<v-text-field 
										:rules="[v => !!v || 'Campo Nome obrigatório']" 
										v-model="grupo.nome" 
										label="Nome" 
										placeholder="Nome" 
										outlined
									/>
								</div>
								<div class="col-12">
									<v-text-field 
										:rules="[v => !!v || 'Campo Tipo obrigatório']" 
										v-model="grupo.tipo" 
										label="Tipo" 
										placeholder="Tipo" 
										outlined
									/>
								</div>
								<div class="col-12">
									<v-select 
										:rules="[v => !!v || 'Campo Menus obrigatório']" 
										:items="menus"
										item-text="nome" 
										item-value="_id"
										v-model="grupo.menu" 
										label="Menus" 
										multiple
										placeholder="Menus" 
										outlined
									/>
								</div>
							</div>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeGrupo">
						Cancelar
					</v-btn>
					<v-btn color="primary" large @click="enviarGrupo">
						Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'
	// importa o plugin de request 
	// import Request from '@/plugins/request'

	// exporta o componente
	export default {
		// nome do componente
		name: 'Grupos',
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// variável para mostrar a modal para editar/criar um grupo
			dialog_grupo: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			menus: [],
			// variável para criar/editar grupo
			grupo: {
				grupo_id: '',
				nome: '',
				tipo: '',
				menu: []
			},
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'nome',
					text: 'Nome',
					sortable: true,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 10,
			},
		}),
		// funções deste componente
		methods: {
			// funções de incio do componente
			async init(){
				// função para pegar as empresas
				this.getMenus()
			},
			// função para pegar os grupos
			async getMenus(){
				// faz a requisição para o back para coletar os grupos
				var resp = await store.dispatch('getMenusSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel grupos
					this.menus = resp.data.dados
				}
			},
			// função para enviar um grupo
			async enviarGrupo(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_grupo.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do grupo
					let dados = await {
						// coleta o nome do grupo
						nome: await this.grupo.nome,
						tipo: await this.grupo.tipo,
						menus: await this.grupo.menu,
					}
					// caso exista um grupo_id 
					if(this.grupo.grupo_id){
						// coleta o id do grupo
						dados.codigo = await this.grupo.grupo_id
					}
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postgrupos', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Grupo '  +  (this.grupo.grupo_id ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Grupo ' + (this.grupo.grupo_id ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.closeGrupo()
						// fecha a modal de create/edit grupo
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um grupo para editar
			async editarGrupo(grupo_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um grupo passando o id 
				var resp = await store.dispatch('getGrupo', grupo_id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200){
					// atribui os dados do grupo vindos do back à váriável local
					this.grupo.grupo_id = await resp.data.dados._id || ''
					this.grupo.nome = await resp.data.dados.nome || ''
					this.grupo.tipo = await resp.data.dados.tipo || ''
					this.grupo.menu = await resp.data.dados.menus || []
					// mostra a modal de creat/edit grupo
					this.dialog_grupo = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// função que roda quando é fechada a modal de create/edit grupo
			closeGrupo(){
				// fecha a modal
				this.dialog_grupo = false
				// limpa os dados locais do grupo
				this.grupo =  {
					grupo_id: '',
					nome: '',
					tipo: '',
					menu: [{
						nome: '',
						icone: '',
						link: '',
					}]
				}
			},
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	#grupos{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
	}
</style>